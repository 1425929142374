@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
* {
  font-family: "Inter", sans-serif;
  color: white;
}

body {
  min-height: 100vh;
  background-color: black;
}
.page {
  background-color: black;
}
.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 58%;
  max-width: 1112px;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 1rem;
  width: 66%;
  @media screen and (max-width: 840px) {
    width: 76%;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 2rem;
  width: 100%;
}

.agency {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 57%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.label {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#contactUs {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

h1 {
  font-size: 80px;
  font-weight: bold;
  margin: 4rem auto 2rem;
  text-align: center;
  @media screen and (max-width: 1024px) {
    font-size: calc(50px + 20 * ((100vw - 390px) / (1024 - 390)));
  }
}

h2 {
  font-size: 20px;
  margin: 0;
  font-weight: 600;
  color: #3050c0;
}

h3 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

p {
  font-size: 16px;
  line-height: 1.5rem;
  margin: 0;
  text-align: center;
}

span {
  color: #3050c0;
}

button {
  margin-top: 2rem;
  border-radius: 500px;
  font-size: 20px;
  padding: 1rem 2rem;
  width: fit-content;
  margin: 2rem auto 0;
  background-color: #3050c0;
  border: none;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 178px;
    font-size: 16px;
  }
}

img {
  width: 70px;
  height: 70px;
  border-radius: 15px;
  @media screen and (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
}
